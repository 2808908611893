import React, { useState } from 'react';
import { Form, Modal, Checkbox, Input, Typography, Button, Select, Col } from 'antd';
    import { createApiIntegration, createApiSimpleIntegration, createDiscordWebhookIntegration, createTelegramTokenIntegration, getXIntegrationUrl, createAiChatIntegration} from '../services/signalApi'
import { selectAccount } from '../features/login/loginSlice'

import { toTitleCase, isBetaUser } from '../utils';
import { DiscordLink } from './DiscordLink'
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { Link } from "react-router-dom";
import { CopyToClipboard } from './CopyToClipboard'
import { IntegrationType } from '../types/types'

const { Option, OptGroup } = Select;


interface IntegrationModalParams {
    isOpen: boolean
    onCancel: () => void
    whitelistedIntegrationTypes?: string[]
    onCreate: () => void
}



interface NewApiIntegration {
    tmp_client_secret?: string
    client_id?: string
    integration_type: IntegrationType
}

interface FormSubmissionValues {
    integration_type: IntegrationType
    name: string
    webhook_url?: string
    token?: string
    provider?: string
    base_url?: string
    available_models?: string
    key?: string
}



export function CreateIntegrationModal({
    isOpen,
    onCancel,
    whitelistedIntegrationTypes,
    onCreate,
}: IntegrationModalParams) {
    const [form] = Form.useForm();
    const terms = Form.useWatch('terms', form);
    const integration_type = Form.useWatch<IntegrationType>('integration_type', form);
    const chosen_provider = Form.useWatch('provider', form);
    const account = useAppSelector(selectAccount);
    const [isCreating, setIsCreating] = useState(false);
    // const [isCreating, setIsCreating] = useState(false);
    const [newIntegration, setNewIntegration] = useState<NewApiIntegration | null>(null);

    const handleCancel = () => {
        form.resetFields()
        setNewIntegration(null)
        setIsCreating(false)
        onCancel()
    }

    const onCreateIntegrationFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const onCreateIntegrationSubmit = (values: FormSubmissionValues) => {
        console.log('Success:', {values, account});
        // TODO - send type
        if (values.integration_type === 'oauth2_api_token') {
            onCreateOauth2ApiIntegration(values)
        } else if (['simple_api_token', 'trading_view_webhook'].includes(values.integration_type)) {
            onCreateSimpleApiIntegration(values)
        } else if (values.integration_type === 'discord_webhook') {
            onCreateDiscordWebhookIntegration(values)
        } else if (values.integration_type === 'telegram_token') {
            onCreateTelegramTokenIntegration(values)
        } else if (values.integration_type === 'x_authentication') {
            // console.log("would create X integration")
            onCreateXIntegration(values)
            // get the oauth url
            // send the user there
            // they authenticate
            // get redirected back to API
            // which saves callback url and sends them back to this page
            // onCreateXIntegration(values)
        } else if (values.integration_type === 'ai_chat_integration') {
            onCreateAiChatIntegration(values)
        }
        // if (account) {
        //     setIsCreating(true)
        //     createApiIntegration(account.id, values.name).then((res) => {
        //         console.log("created", {res})
        //         const response_data = res.data
        //         const tmp_client_secret = response_data.tmp_client_secret
        //         const client_id = response_data.application.client_id
        //         setIsCreating(false)
        //         setNewIntegration({client_id, tmp_client_secret})
        //     }
        //     ).catch().finally(() => {
        //         setIsCreating(false)
        //     })
        // }
    };

    const onCreateOauth2ApiIntegration = (values: FormSubmissionValues) => {
        if (account) {
            setIsCreating(true)
            createApiIntegration(account.id, values.name).then((res) => {
                console.log("created", {res})
                const response_data = res.data
                const tmp_client_secret = response_data.tmp_client_secret
                const client_id = response_data.application.client_id
                setIsCreating(false)
                setNewIntegration({client_id, tmp_client_secret, integration_type: 'oauth2_api_token'})
            }
            ).catch().finally(() => {
                setIsCreating(false)
                onCreate()
            })
        }
    }

    const onCreateSimpleApiIntegration = (values: FormSubmissionValues) => {
        if (account) {
            setIsCreating(true)
            createApiSimpleIntegration(account.id, values.name, values.integration_type).then((res) => {
                console.log("created", {res})
                const response_data = res.data
                const tmp_client_secret = response_data.tmp_client_secret
                const integration_type = response_data.type

                // const client_id = response_data.application.client_id
                setIsCreating(false)
                setNewIntegration({tmp_client_secret, integration_type})
            }
            ).catch().finally(() => {
                setIsCreating(false)
                onCreate()
            })
        }
    }

    const onCreateDiscordWebhookIntegration = (values: FormSubmissionValues) => {
        if (account && values.webhook_url) {
            setIsCreating(true)
            createDiscordWebhookIntegration(account.id, values.name, values.webhook_url).then((res) => {
                console.log("created", {res})
                const response_data = res.data
                const integration_type = response_data.type

                // const client_id = response_data.application.client_id
                setIsCreating(false)
                setNewIntegration({integration_type})
            }
            ).catch().finally(() => {
                setIsCreating(false)
                onCreate()
            })
        }
    }

    const onCreateTelegramTokenIntegration = (values: FormSubmissionValues) => {
        if (account && values.token) {
            setIsCreating(true)
            createTelegramTokenIntegration(account.id, values.name, values.token).then((res) => {
                console.log("created", {res})
                const response_data = res.data
                const integration_type = response_data.type

                // const client_id = response_data.application.client_id
                setIsCreating(false)
                setNewIntegration({integration_type})
            }
            ).catch().finally(() => {
                setIsCreating(false)
                onCreate()
            })
        }
    }

    const onCreateXIntegration = (values: FormSubmissionValues) => {
        if (account) {
            setIsCreating(true)
            getXIntegrationUrl(account.id).then((res) => {
                console.log("created", {res})
                if (res.status === 200) {

                    const response_data = res.data
                    const integration_type = response_data.type

                    // const client_id = response_data.application.client_id
                    setIsCreating(false)
                    setNewIntegration({integration_type})
                    window.location.href = response_data.url
                }

            }
            ).catch().finally(() => {
                setIsCreating(false)
                onCreate()
            })
        }
    }

    const onCreateAiChatIntegration = (values: FormSubmissionValues) => {
        console.log("would create AI chat integration", {values})

        if (account && values.key && values.provider) {
            setIsCreating(true)
            const creationPromise =  (values.provider === 'custom' && values.base_url && values.available_models) ? createAiChatIntegration({
                account_id: account.id,
                name: values.name,
                key: values.key,
                provider: "custom",
                base_url: values.base_url,
                available_models: values.available_models
            }) : createAiChatIntegration({
                account_id: account.id,
                name: values.name,
                key: values.key,
                provider: values.provider,
                ...(values.available_models ? {available_models: values.available_models} : {})
            })
            creationPromise.then((res) => {
                console.log("created", {res})
                const response_data = res.data
                const integration_type = response_data.type

                // const client_id = response_data.application.client_id
                setIsCreating(false)
                setNewIntegration({integration_type})
            }
            ).catch().finally(() => {
                setIsCreating(false)
                onCreate()
            })
        }

    }


    const enabled_integration_types = ['oauth2_api_token', 'simple_api_token', 'discord_webhook', 'telegram_token', 'trading_view_webhook', ...(isBetaUser(account?.name) ? ['x_authentication', 'ai_chat_integration'] : [])]
    const requires_name_param = !["x_authentication"].includes(integration_type)
    const requires_webhook_url_param = ['discord_webhook'].includes(integration_type)
    const requires_token_param = ['telegram_token'].includes(integration_type)
    const enabled_integration_type = enabled_integration_types.includes(integration_type)
    const token_integration = ['oauth2_api_token', 'simple_api_token'].includes(integration_type)

    console.log("CreateIntegration", {isCreating, newIntegration, enabled_integration_type, terms})

    return (
            <Modal title="Create Integration" open={isOpen} footer={null} onCancel={handleCancel} width={600}>
            <Form
                name="basic"
                form={form}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 16 }}
                initialValues={{ integration_type: 'oauth2_api_token', terms: false }}
                onFinish={onCreateIntegrationSubmit}
                onFinishFailed={onCreateIntegrationFailed}
                autoComplete="off"
                requiredMark={"optional"}
                >
                    
                    {(isCreating && (
                        <Typography.Paragraph>Loading...</Typography.Paragraph>
                    ))}
                    <Typography.Paragraph>
                        Creation of an API Integration. {token_integration ? (`You will get tokens that you can use to issue HTTP requests.
                        This will only be shown to you once. If you lose the credentials or believe them to be compromised, please delete them and create new ones.
                        You are responsible for any activity that happens with your tokens. Keep them safe.`) : ''} 
                    </Typography.Paragraph>

                    {(newIntegration === null && (
                        <>
                            <Form.Item
                                label="Type"
                                name="integration_type"
                                required
                                extra={<>Learn more about integrations <Link to={`/documentation`}>here</Link>.</>}
                                >
                                    <Select>
                                        <OptGroup label="Web">
                                            <Option value="oauth2_api_token">OAuth2 API Token</Option>
                                            <Option value="simple_api_token">Simple API Token</Option>
                                            {/* <Option value="websocket">Websocket</Option> */}
                                        </OptGroup>
                                        <OptGroup label="AI Ecosystem">
                                            <Option value="ai_chat_integration">AI Chat Integration</Option>
                                        </OptGroup>
                                        <OptGroup label="Solutions">
                                            {/* <Option value="rss">RSS Feed</Option> */}
                                            <Option value="x_authentication">X / Twitter</Option>
                                            <Option value="linkedin_authentication">LinkedIn</Option>
                                            <Option value="google_analytics">Google Analytics</Option>
                                            <Option value="wordpress">Wordpress</Option>
                                            {/* <Option value="trading_view_receiver">Trading View Webhook</Option> */}
                                            {/* <Option value="google_data_studio">Google Data Studio</Option> */}
                                            {/* <Option value="discord_webhook">Discord Webhook</Option> */}
                                            {/* <Option value="telegram_token">Telegram Token</Option> */}
                                            {/* <Option value="email_newsletter">Email Newsletter</Option> */}
                                            {/* <Option value="trading_view_webhook">Trading View Webhook</Option> */}
                                            {/* <Option value="stitch">Stitch</Option> */}
                                        </OptGroup>
                                        {/* <OptGroup label="AWS">
                                            <Option value="aws_firehose">AWS Firehose</Option>
                                            <Option value="aws_s3">AWS S3</Option>
                                            <Option value="aws_sns">AWS SNS</Option>
                                        </OptGroup>
                                        
                                        <OptGroup label="Other">
                                            <Option value="sftp">SFTP Server</Option>
                                        </OptGroup> */}

                                    </Select>
                            </Form.Item>

                            {(!enabled_integration_type) && (

                                <Col span={16} offset={6}>
                                    <Typography.Paragraph>Coming soon! <Link to={`/contact_us`}>Contact us</Link> if you would like to join the beta and work with us to roll this out.</Typography.Paragraph>
                                </Col>
                            )}
                            {requires_name_param && (
                                <Form.Item
                                    label="Name"
                                    name="name"
                                    required
                                    rules={[
                                        { required: true, message: 'Please name your Integration' },
                                        // { type: 'enum', enum: ['form_submit'], message: 'Not available to you. Sign up for access.'}
                                    ]}
                                >
                                    <Input disabled={!enabled_integration_type} />
                                </Form.Item>
                            )}

                            {integration_type === 'ai_chat_integration' && (
                                <>
                                    <Form.Item
                                        label="Provider"
                                        name="provider"
                                        required={integration_type === 'ai_chat_integration'}
                                    >
                                        <Select
                                            options={[
                                                {value: "openai", label: "OpenAI"},
                                                {value: "groq", label: "Groq"},
                                                {value: "xai", label: "xAI"},
                                                {value: "perplexity", label: "Perplexity"},
                                                {value: "custom", label: "Custom API (specify base url)"},
                                            ]}
                                        />

                                    </Form.Item>

                                    {chosen_provider === "custom" && (
                                        <Form.Item
                                            label="Base URL"
                                            name="base_url"
                                            required={chosen_provider==='custom'}
                                            extra={<>The base url of the Chat Integration. See <Link to={`/documentation`}>Documentation</Link>.</>}
                                            rules={[
                                                { required: true, message: 'Please provide a Base URL for a Custom Provider' },
                                                // { type: 'enum', enum: ['form_submit'], message: 'Not available to you. Sign up for access.'}
                                            ]}
                                        >
                                            <Input placeholder="https://api.perplexity.ai"/>
                                        </Form.Item>
                                    )}

                                    <Form.Item
                                        label="API Key"
                                        name="key"
                                        required
                                        extra={<>The API Key from the AI API Provider</>}
                                        rules={[
                                            { required: true, message: 'Please provide your API Key' },
                                            // { type: 'enum', enum: ['form_submit'], message: 'Not available to you. Sign up for access.'}
                                        ]}
                                    >
                                        <Input.Password placeholder="sk-12345" />
                                    </Form.Item>

                                    <Form.Item
                                        label="Models"
                                        name="available_models"
                                        extra={<>Comma-Separated list of models to allow from provider</>}
                                        required={chosen_provider==='custom'}
                                        rules={[
                                            ...(chosen_provider==='custom' ? ([{ required: true, message: 'Please provide which models to allow' }]) : []),
                                            // { type: 'enum', enum: ['form_submit'], message: 'Not available to you. Sign up for access.'}
                                        ]}
                                    >
                                        <Input placeholder="gpt-3.5, gpt-4o"/>
                                    </Form.Item>
                                </>
                            )}

                            {requires_webhook_url_param && (
                                <Form.Item
                                    label="Webhook URL"
                                    name="webhook_url"
                                >
                                    <Input />
                                </Form.Item>
                            )}

                            {requires_token_param && (
                                <Form.Item
                                    label="Token"
                                    name="token"
                                >
                                    <Input />
                                </Form.Item>
                            )}

                            

                            <Form.Item name="terms" valuePropName="checked" wrapperCol={{ span: 16, offset: 6 }}
                                rules={[{ required: true, message: 'Terms must be accepted' }]}
                            >
                                <Checkbox disabled={!enabled_integration_type}>Agree to <a href='/terms_and_conditions' target='blank'>Terms</a></Checkbox>
                            </Form.Item>

                            <Form.Item wrapperCol={{ span: 16, offset: 6 }}>
                                <Button type="primary" htmlType="submit" disabled={isCreating || newIntegration !== null || !enabled_integration_type || !terms}>
                                {integration_type === "x_authentication" ? "Integrate with X" : token_integration ? "Create Token" : "Create Integration"}
                                </Button>
                            </Form.Item>
                        </>
                    ))}

                    {(newIntegration && (
                        <>
                            <Typography.Paragraph>Integration Created!</Typography.Paragraph>
                            {newIntegration.integration_type === 'oauth2_api_token' && newIntegration.client_id !== undefined && newIntegration.tmp_client_secret !== undefined && (
                                <>
                                    <Typography.Paragraph>Save these now.</Typography.Paragraph>
                                    <Typography.Paragraph>Client ID:</Typography.Paragraph>
                                    <Typography.Paragraph>
                                        <Typography.Text code>{newIntegration.client_id}</Typography.Text>
                                        <CopyToClipboard text={newIntegration.client_id} />
                                    </Typography.Paragraph>
                                    <Typography.Paragraph>Client Secret:</Typography.Paragraph>
                                    <Typography.Paragraph>
                                        <Typography.Text code>{newIntegration.tmp_client_secret}</Typography.Text>
                                        <CopyToClipboard text={newIntegration.tmp_client_secret} />
                                    </Typography.Paragraph>
                                </>
                            )}

                            {['simple_api_token', 'trading_view_webhook'].includes(newIntegration.integration_type) && newIntegration.tmp_client_secret !== undefined && (
                                <>
                                    <Typography.Paragraph>Token (save this now):</Typography.Paragraph>
                                    <Typography.Paragraph>
                                        <Typography.Text code>{newIntegration.tmp_client_secret}</Typography.Text>
                                        <CopyToClipboard text={newIntegration.tmp_client_secret} />
                                    </Typography.Paragraph>
                                </>
                            )}
                            
                            

                            <Button type="primary" onClick={handleCancel}>
                                Okay
                            </Button>
                        </>

                    ))}
    
                </Form>
            </Modal>
    )

    
}
