import type { Account as AccountType} from "../types/types"
import { useQuery } from '@tanstack/react-query';
import { getInfoSets, getDataSets, getProjects, getPromptPacks, getDataSetsLoggedOut, getIntegrations, getAiChatIntegrations } from '../services/signalApi';

import { COMMON_EMPTY_RESPONSE } from '../utils';

const TIME_15_MINUTE = 1000 * 60 * 15;
const TIME_HOUR = 1000 * 60 * 60;


export const useInfoSets = (account: AccountType | undefined) => {
    const {
        data: { data: { count: info_sets_count, results: info_sets}} = COMMON_EMPTY_RESPONSE,
        refetch: refetchInfoSets,
        isFetching: isFetchingInfoSets,
    } = useQuery({
        queryKey: ['account', account?.id, 'info_sets'],
        staleTime: TIME_HOUR,
        queryFn: () => {
            return getInfoSets({})
        }
    })
    return {info_sets_count, info_sets, refetchInfoSets, isFetchingInfoSets}
}

export const useDataSets = (account: AccountType | undefined) => {
    const {
        data: { data: { count: data_sets_count, results: data_sets}} = COMMON_EMPTY_RESPONSE,
        refetch: refetchDataSets,
        isFetching: isFetchingDataSets,
    } = useQuery({
        queryKey: ['account', account?.id, 'data_sets'],
        staleTime: TIME_HOUR,
        queryFn: () => {
            const getDataSetsFn = account ? getDataSets : getDataSetsLoggedOut;
            return getDataSetsFn({ordering: 'created_date'})
        }
    })

    return {data_sets_count, data_sets, refetchDataSets, isFetchingDataSets}
}

export const useProjects = (account: AccountType | undefined) => {
    const {
        data: { data: { count: projects_count, results: projects}} = COMMON_EMPTY_RESPONSE,
        refetch: refetchProjects,
        isFetching: isFetchingProjects,
    } = useQuery({
        queryKey: ['account', account?.id, 'projects'],
        staleTime: TIME_15_MINUTE,
        queryFn: () => {
            return getProjects({
                ordering: '-created_date'
            })
        }
    })
    return {projects_count, projects, refetchProjects, isFetchingProjects}
}

export const usePromptPacks = (account: AccountType | undefined) => {
    const {
        data: { data: { count: prompt_packs_count, results: prompt_packs}} = COMMON_EMPTY_RESPONSE,
        refetch: refetchPromptPacks,
        isFetched: isFetchedPromptPacks,
        isFetching: isFetchingPromptPacks,
    } = useQuery({
        queryKey: ['account', account?.id, 'prompt_packs'],
        staleTime: TIME_HOUR,
        queryFn: () => {
            // const prompt_ids_needed = prompt_ids.filter((id) => id && !workflowDataSetIds.includes(id))
            return getPromptPacks({ordering: 'created_date'})
        }
    })
    return {prompt_packs_count, prompt_packs, refetchPromptPacks, isFetchedPromptPacks, isFetchingPromptPacks}
}

export const useIntegrations = (account: AccountType | undefined) => {
    const {
        data: { data: { count: integrations_count, results: integrations}} = COMMON_EMPTY_RESPONSE,
        refetch: refetchIntegrations,
        isFetching: isFetchingIntegrations,
    } = useQuery({
        queryKey: ['account', account?.id, 'integrations'],
        staleTime: TIME_HOUR,
        queryFn: () => {
            return getIntegrations()
        }
    })
    return {integrations_count, integrations, refetchIntegrations, isFetchingIntegrations}
}

export const useAiChatIntegrations = (account: AccountType | undefined) => {
    const {
        data: { data: { count: ai_chat_integrations_count, results: ai_chat_integrations}} = COMMON_EMPTY_RESPONSE,
        refetch: refetchAiChatIntegrations,
        isFetching: isFetchingAiChatIntegrations,
    } = useQuery({
        queryKey: ['account', account?.id, 'ai_chat_integrations'],
        staleTime: TIME_HOUR,
        queryFn: () => {
            return getAiChatIntegrations()
        }
    })
    return {ai_chat_integrations_count, ai_chat_integrations, refetchAiChatIntegrations, isFetchingAiChatIntegrations}
}
// workflows, account info
