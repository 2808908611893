import { useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";

import { Typography, Row, Col, Card, Button } from 'antd';
import { MarkdownContent } from '../../components/MarkdownContent';
import { NewsLetterRegister } from '../../components/NewsLetterRegister';
import { StoreFront } from '../../components/StoreFront';
import { SignUpButton } from '../../components/SignUpButton';
import { GetPromoCodeButton } from '../../components/GetPromoCodeButton';


import { useAppSelector } from '../../app/hooks';
import { selectAccount } from '../../features/login/loginSlice';

// Code blocks? https://github.com/rajinwonderland/react-code-blocks
// const commonStyles = {padding: '0 50px 50px', maxWidth: "1200px"}
const commonStyles = {}
const regularStyles = {fontSize: '1.0rem', ...commonStyles}


interface PageMarkdownContentWithSideCardParams {
    title: string
    markdown: string
    sideCardTitle?: string
    sideCard?: string
    action: React.ReactElement
    image: string
    additional_page_content_styles?: React.CSSProperties
}

function PageMarkdownContentWithSideCard({
    title,
    markdown,
    sideCardTitle,
    sideCard,
    action,
    image,
    additional_page_content_styles,
}: PageMarkdownContentWithSideCardParams) {

    const styles = additional_page_content_styles ? additional_page_content_styles : regularStyles

    return (
        <div className="App">
          <div className="App-content">
          <Typography.Title level={1}></Typography.Title>

            <div className="App-page-content">
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col xs={24} md={12}>
                    <Typography.Title level={1} style={{marginTop: 0}}>{title}</Typography.Title>
                    <MarkdownContent markdown={markdown} style={{fontSize: "1.2rem"}}/>
                    </Col>
                    <Col xs={24} md={{span: 10, offset: 2}}>
                        <Card
                                cover={
                                    <img
                                        alt="example"
                                        src={`https://assets.pyroprompts.com/img/${image}`}
                                    />
                                }
                            >
                                {sideCardTitle && (
                                    <Typography.Title level={3} style={{marginTop: 0}}>{sideCardTitle}</Typography.Title>

                                )}
                                {sideCard && (
                                    <MarkdownContent markdown={sideCard} style={{fontSize: "0.9rem"}} />

                                )}
                            {action}
                        </Card>
                    
                    </Col>

                </Row>


            
            </div>


          </div>
        </div>
      );
}


function SqueezeWorkflowScheduling() {
    const account = useAppSelector(selectAccount);
    const [searchParams, setSearchParams] = useSearchParams();

    const markdown = `Create discipline and consistency with scheduled Workflows

- Your content schedule for the upcoming day, week or month
- Your personal Weekly newsletter, filled with what is interesting to you
- AI generated ideas or questions that speak to your audience
- Planning next steps or building task lists
- Monitoring changes in AI responses

Run any prompt daily, weekly or monthly with PyroPrompts Scheduled Workflows


    `

    const sideCardContent = account ? `Start scheduling now
1. Create a [Project](/projects)
2. Create a Workflow in that Project
3. Set the Workflow's Trigger to Timed, Select the Time
4. For the first action, select Execute Prompt and select the Prompt to run. Set the whole response to \`response\`
5. For the second action, select Send Email. Give it a subject and body. In the body, use \`[response]\`
6. Select "Inject Vars" and enter \`response\` and then choose the First Step's Response to inject.
7. Save and Enable
` : `Start scheduling now
1. Sign up
2. Create a Project
3. Create a Workflow
4. Set the Workflow's Trigger to Timed, Select the Time
5. For the first action, select Execute Prompt and select the Prompt to run. Set the whole response to \`response\`
6. For the second action, select Send Email. Give it a subject and body. In the body, use \`[response]\`
7. Select "Inject Vars" and enter \`response\` and then choose the First Step's Response to inject.
8. Save and Enable
    `


    useEffect(() => {document.title = 'PyroPrompts | Schedule AI with Workflows'}, [])
    return <PageMarkdownContentWithSideCard
        title="Schedule AI with Workflows"
        markdown={markdown}
        sideCardTitle="Personal AI in your Inbox Daily"
        sideCard={sideCardContent}
        action={account ? (
            <div>
                <Link to="/projects">
                    <Button type='primary' size='large' style={{width: '100%', marginTop: '12px', height: '48px'}}>
                        Yes, Automate my AI
                    </Button>
                </Link>
            </div>
        ) : (
            <div>
                <SignUpButton text='Yes, Automate my AI' style={{width: '100%', marginTop: '12px', height: '48px'}} />
            </div>
        )
        }
        image='clock-creation-8-520x200.png'
    />
}

function SqueezeWorkflowEventHandling() {
    const markdown = `Run from anywhere with Workflow Webhooks

- Connect to GPT Actions
- Run from orchestration tools, like Zapier or Make
- Integrate into your own website or apps
- Much much more. Many applications support webhooks


Connect our powerful AI to your existing orchestration ecosystem


    `

    const sideCardContent = `Sign up for the Waitlist`
    const account = useAppSelector(selectAccount);


    useEffect(() => {document.title = 'PyroPrompts | Workflow Event Handling'}, [])

    return <PageMarkdownContentWithSideCard
        title="Workflow Event Handling"
        markdown={markdown}
        sideCardTitle="In Beta Now"
        sideCard={sideCardContent}
        action={
            <NewsLetterRegister sign_up_button_text='Subscribe'/>
        }
        image='clock-creation-8-520x200.png'
    />
}

function SqueezeWorkflowHosting() {
    const markdown = `Create Workflows that other people run, like GPTs

- Your personalized Customer Service AI
- Form processing with AI
- Get emailed when the workflow runs
- Make money with our integrated monetization controls.
- Understand your users better by reviewing or using AI to review their conversations.


GPTs are great. These enable a bit more structured control than GPTs.


    `

    const sideCardContent = `Sign up for the Waitlist`
    const account = useAppSelector(selectAccount);


    useEffect(() => {document.title = 'PyroPrompts | AI Workflow Hosting'}, [])
    // return getPageMarkdownContentWithSideCard(
    //     "AI Workflow Hosting", markdown,
    //     "In Beta Now", sideCardContent,
    //     'newsletter', 'clock-creation-8-520x200.png'
    // )
    return <PageMarkdownContentWithSideCard
        title="AI Workflow Hosting"
        markdown={markdown}
        sideCardTitle="In Beta Now"
        sideCard={sideCardContent}
        action={
            <NewsLetterRegister sign_up_button_text='Subscribe'/>
        }
        image='clock-creation-8-520x200.png'
    />
}

function SqueezeExtension() {
    const markdown = `Save Time and reuse Prompts with Snippet Shortcuts

Download the 100% Free PyroPrompts Chrome Extension

- Easily insert snippets anywhere with a simple keystroke.

- Private by nature, prompts stored locally in your browser.

- Unlimited prompts of unlimited size, up to the 10mb extension limit.

- No need to register or upload prompts to PyroPrompts.com, though we hope you do!

Prompting has never been easier, download today!
    `

    const sideCardContent = `Save hours of typing or copying and pasting, start prompting with purpose`

    const cta = () => {
        return <div>
            <a href="https://chromewebstore.google.com/detail/pyroprompts-ai/cibjncdngifbjjhephmmhbjaghngddei" target="blank">
            <Button type='primary' size='large' style={{width: '100%', marginTop: '12px', height: '48px'}}>
                Better Prompts, Download Now
            </Button>
    </a>
            
        </div>
    }


    useEffect(() => {document.title = 'PyroPrompts | AI Extension'}, [])
    // return getPageMarkdownContentWithSideCard(
    //     "AI Workflow Hosting", markdown,
    //     "In Beta Now", sideCardContent,
    //     'newsletter', 'clock-creation-8-520x200.png'
    // )
    return <PageMarkdownContentWithSideCard
        title="Save Prompts in your Browser"
        markdown={markdown}
        sideCardTitle="Download Now"
        sideCard={sideCardContent}
        action={cta()}
        image='casino-hangover-illustration-2-520x200.png'
    />
}


function SqueezeNewYearNoCodeAiMarketingMasters() {
    const markdown = `We are running an elite AI automation program for people who want to get serious about scaling in 2024 for **$900**.

- You'll get **5000 credits** for PyroPrompts that never expire, a **$500** value. Should be enough to run all of 2024.

- You'll get monthly hands-on one-on-one done-with-you automation implementation and consulting where we learn your needs and build your AI marketing automation together, providing you with the custom AI automation you need and the training to create your own automation workflows, at least a **$2400** value.

- You'll get to join our exclusive **Pyro Pros** group where you can chat and collaborate with other Pros to learn new strategies, share successes and grow together, a **$500** value.

- You'll get to join our weekly **Pyro Pros** conference call for a live Q&A, case studies, discuss feature requests and share stories, a **$5000** value.

All of this, a **$8400** value, for only **$900**, 89% off.

After our first one-on-one you will have AI automation running, optionally landing in your email inbox. If you don't feel like you want to commit to a year of adopting AI into your business or don't feel like it's the right solution for you, **I'll refund your money, no questions asked**. Either you get automation you're happy with and a path towards a successful 2024 or you can back out.

We only have 50 spots and are closing this offer at the end of January. The sooner you sign up, the sooner we can meet and begin automating your AI so you can grow like wildfire in 2024. Signing up today gives you the most value.

We are stoked to work with great marketing minds to automate their workflows, grow their businesses, track their results, and test and iterate on solutions for a year. This could open up new collaboration opportunities we'd never even considered. The AI space is so wide-open right now, we have the ability to light it aflame!
`

    const sideCardContent = `Serious Collaboration for Serious Scale

- 5000 Credits

- 1-1 Catered Automation Built With You

- Access to Pyro Pros Group Chat

- Weekly Pyro Pros Conference Call

$8400 Value. Join now for $900. 50 Spots Total. Closes in January.

`
    const cta = () => {
        // return <StoreFront skus={['new_year_no_code_ai_marketing_masters_2023']} style='list' />
        return <div>
            <Button type='primary' disabled={true} size='large' style={{width: '100%', marginTop: '12px', height: '48px'}}>
                Registration is Closed
            </Button>
        </div>
    }


    useEffect(() => {document.title = 'PyroPrompts | New Year No-Code AI Marketing Masters'}, [])
    return <PageMarkdownContentWithSideCard
        title="New Year No-Code AI Marketing Masters"
        markdown={markdown}
        sideCardTitle="Join Now"
        sideCard={sideCardContent}
        action={cta()}
        image='fire-robot-1-520x200.png'
    />
}

function SqueezeMarketAnalysisPromptPack() {
    const account = useAppSelector(selectAccount);

    const markdown = `Uncover key market insights and strategically position yourself to serve your niche.

Use our AI-driven framework to learn about what drives your target and what challenges you want to address for them.

- Research what your target market dreams of achieving and what they avoid. You can use this in determining what challenges they face and how to message them.

- Learn what challenges they face in achieving their dreams and avoiding their fears. You can use this to determine which problems to solve.

- Ideate on what deliverables you can provide to solve those challenges.

- Get a full target-market report emailed to you on-demand.


Businesses pay thousands of dollars to consultants and agencies to do this for them over the course of weeks.

Get the workflows and prompts so you can run it in minutes.


`

    const sideCardContent = `What your customers are looking for and what you can give them.

- You get the framework, which has been adapted from a number of business books.

- You can run the framework through PyroPrompts with a single click.

- You get access to the prompts that are used so you can adapt them or take them to other AI providers, like ChatGPT.

After purchasing the Prompt Pack, go to [Projects](/projects), create a new Project and Preload with a "Copy from Prompt Pack Project: Market Research Template". Enjoy!

`
    const cta = () => {
        return <StoreFront skus={['market_analysis_prompt_pack_24']} style='list' cardStyle={{boxShadow: 'none'}} storeRowStyle={{}} />

    }


    useEffect(() => {document.title = 'PyroPrompts | Market Analysis Prompt Pack'}, [])
    return <PageMarkdownContentWithSideCard
        title="Market Analysis Prompt Pack"
        markdown={markdown}
        sideCardTitle="Proven Market Analysis Framework"
        sideCard={sideCardContent}
        action={cta()}
        image='fire-robot-1-520x200.png'
    />
}

function SqueezeForMarketers() {

    const markdown = `*Quickly* learn what drives your target market, so you can message them more effectively and ultimately *sell them more*.

The Marketing Analysis Prompt Pack has workflows and prompts that work together to:

- Analyze your Target Market's Dreams and Fears, so you know what to offer them and how to message them, to help **you sell more**.

- Learn what challenges they face in achieving their dreams and avoiding their fears so you can help them overcome those challenges and **earn trust or sell services and products**.

- Create Lead Magnet and Offer ideas so you can **attract and sell to your target market**.

- Generate questions you can use during the sales process to challenge your prospects, so you can **convert more**.

- Craft content ideas you can use for **marketing** or as **info-products** so you don't need to spend time every week thinking of ideas.

- Run an AI Focus Group that will evaluate your offer and sales pitch so you can get some **immediate feedback**.

 
### Bonus Workflows and Prompts!

This includes full access the workflows and prompts used in the following public workflows, so you can review them, copy the prompts or edit the prompts and run your personalized versions yourself.

- Research SEO Terms that your target market will use to find your product with [SEO: Lookup Relevant Search Terms](https://pyroprompts.com/w/website-seo-keywords).

- Craft Landing Page Content with [Magic Landing Pages](https://pyroprompts.com/w/landing-page-content).

- Name your offers with [Name My Offer](https://pyroprompts.com/w/promotional-offer-names).

- Write catchy platitudes that your target audience needs to hear with [Get Catchy Platitudes](https://pyroprompts.com/w/catchy-platitudes).

- Get interview questions that your fans will want answered with [Skip Stone: Interviewer](https://pyroprompts.com/w/interview-questions-skip-stone).

 
Businesses pay thousands of dollars to consultants and agencies to do this for them over the course of weeks.

Get the workflows and prompts so you can run it in minutes as many times as you want.

`

    const cta = () => {
        return <StoreFront skus={['market_analysis_prompt_pack_24']} style='list' cardStyle={{boxShadow: 'none'}} storeRowStyle={{}} />

    }


    useEffect(() => {document.title = 'PyroPrompts | AI for Digital Marketers'}, [])
    return <PageMarkdownContentWithSideCard
        title="AI for Digital Marketers"
        markdown={markdown}
        sideCardTitle={undefined}
        sideCard={undefined}
        action={cta()}
        image='fire-robot-1-520x200.png'
    />
}


function SqueezeBestSummerEver() {

    const markdown = `Have a great summer!

No, really, have *the best summer ever*.

Have the best summer of your life. Expand on your normal habits and do something memorable or learn a new skill. Take a trip, complete a challenge, build something. Too often the days and weeks float on by.

*What does your ideal summer look like?*

Is it enjoying the same thing every day? If so, great, routine can be really enjoyable. But if you're looking to introduce some memorable things, plan for it. Do you want to go to any shows or events? Take a week (or more) vacation somewhere you haven't been to before. New hikes to new peaks? Maybe there's a skill you'd like to pick up this summer. Or just get a great picture on the beach with your kids, one you can put up on the wall and look at for years to come.

These things don't just happen. You need to plan for them.

But who has time to plan out everything? You have more important things to do. You want to enjoy the beach, not plan a trip to the beach.

You want to remember a great bbq with family and friends, not be indecisive about what to make and whom to invite so that you put the whole thing off until next year.

So, let's use AI to help you make memories and plan an excellent summer.

Here's a [GPT for Summer Planning](https://chat.openai.com/g/g-wKBD0YEmZ-best-summer-ever-assistant). If you pay for ChatGPT, give it a spin!

But, if you want to get these sent to you every week (or day), you might want this automated.

So, we have a special package for you!

### Best Summer Ever Prompt Pack

All you need for an AI Assistant to inspire your summer plans.

- Summer Assistant Prompt, pre-filled with instruction on how to plan and grow this summer, so you can get the inspiration without working on AI.

- Automation Template to run this prompt every week on Llama3 and send directly to your email inbox, so you don't even need to log in.

- 300 Credits, 10%-off, so you have enough to run through the Summer and beyond, allowing you to tinker if you want to or keep enjoying plans all year long.

Don't let another summer slip by, get personalized inspiration every single week.

`

    // const cta = () => {
    //     return <StoreFront skus={['best_summer_ever_prompt_pack_24']} style='list' cardStyle={{boxShadow: 'none'}} storeRowStyle={{}} />
    //
    // }

    const cta = () => {
        // return <StoreFront skus={['new_year_no_code_ai_marketing_masters_2023']} style='list' />
        return <div>
            <Button type='primary' disabled={true} size='large' style={{width: '100%', marginTop: '12px', height: '48px'}}>
                No Longer Available
            </Button>
        </div>
    }


    useEffect(() => {document.title = 'PyroPrompts | Best Summer Ever with AI'}, [])
    return <PageMarkdownContentWithSideCard
        title="Best Summer Ever with AI"
        markdown={markdown}
        sideCardTitle={undefined}
        sideCard={undefined}
        action={cta()}
        image='summer-ai-520x200.png'
    />
}


function BlackFridayContent() {

    const markdown = `This Black Friday, automate your work and get more done while spending less.

*Imagine having AI work for you while you enjoy time with family and friends.*

Too often we get caught up in the daily grind, spending hours on tasks that could be automated. Whether it's:
- Writing social media content
- Analyzing market trends
- Generating creative ideas
- Summarizing long documents
- Planning your week ahead

What if AI could handle these tasks while you focus on what truly matters?

### Introducing Our Black Friday Automation Special

Get your AI automation running with our incredible one-time offer:

**200 Credits for just $10** - that's *50% more credits* at the same price!

- Regular price: 100 credits for $10
- Black Friday Special: 200 credits for $10
- **Save 50%!**

### Why This Is Amazing

- **Set It and Forget It**: Create workflows that run automatically and deliver results to your inbox
- **No Chat Required**: Skip the back-and-forth with AI - get results directly in your email
- **Maximum Flexibility**: Buy as many times as you want during this special offer
- **Never Expires**: Credits don't expire, use them whenever you need them

### How It Works

1. Purchase credits at this special Black Friday price
2. Set up your automated workflows
3. Receive AI-generated content and insights directly in your inbox
4. Enjoy more free time while AI works for you

Don't miss out on this limited-time offer to supercharge your productivity with AI automation.

*This offer ends after Black Friday - act now!*
`

    const cta = () => {
        return <StoreFront skus={['BLACK_FRIDAY']} style='list' cardStyle={{boxShadow: 'none'}} storeRowStyle={{}} />

    }

    // const cta = () => {
    //     // return <StoreFront skus={['new_year_no_code_ai_marketing_masters_2023']} style='list' />
    //     return <div>
    //         <Button type='primary' disabled={true} size='large' style={{width: '100%', marginTop: '12px', height: '48px'}}>
    //             No Longer Available
    //         </Button>
    //     </div>
    // }


    useEffect(() => {document.title = 'PyroPrompts | Black Friday Special'}, [])
    return <PageMarkdownContentWithSideCard
        title="Black Friday Special 50% Off"
        markdown={markdown}
        sideCardTitle={undefined}
        sideCard={undefined}
        action={cta()}
        image='black-friday-520x200.png'
    />
}

function CyberMondayContent() {

    const markdown = `This Cyber Monday, supercharge your productivity with AI automation at our best price ever.

*Let AI handle your routine tasks while you focus on what matters most.*

Are you spending too much time on repetitive tasks like:
- Writing email responses
- Creating social media content
- Analyzing data and reports
- Brainstorming ideas
- Planning and scheduling

What if you could automate all of these tasks with AI working for you 24/7?

### Introducing Our Cyber Monday Mega Deal

Get started with AI automation with our biggest discount ever:

**300 Credits for just $10** - that's *200% more credits* at the same price!

- Regular price: 100 credits for $10
- Cyber Monday Special: 300 credits for $10
- **Save 67%!**

### Why You'll Love This Deal

- **Automated Workflows**: Set up once, run forever - get results while you sleep
- **Email Integration**: Receive AI-generated content directly in your inbox
- **Unlimited Purchases**: Stock up now at this incredible price
- **Never Expires**: Use your credits whenever you need them

### Getting Started is Easy

1. Get your credits at this special Cyber Monday price
2. Create your automated workflows
3. Let AI deliver results straight to your inbox
4. Watch your productivity soar while AI does the heavy lifting

Don't miss this opportunity to get our best deal ever on AI automation.

*This special offer ends after Cyber Monday - grab it while you can!*
`

    const cta = () => {
        return <StoreFront skus={['CYBER_MONDAY']} style='list' cardStyle={{boxShadow: 'none'}} storeRowStyle={{}} />
    }

    useEffect(() => {document.title = 'PyroPrompts | Cyber Monday Special'}, [])
    return <PageMarkdownContentWithSideCard
        title="Cyber Monday Special 200% Extra"
        markdown={markdown}
        sideCardTitle={undefined}
        sideCard={undefined}
        action={cta()}
        image='cyber_monday-520x200.png'
    />
}



function RetrievalAugmentedGeneration() {

    const markdown = `Have you ever wanted AI to know more?

AI hasn't been trained on current events. It doesn't know the content of a book you're reading.

But, it can make stuff up. Gross.

Stop spending time copying and pasting current events. Start using RAG to pull in data from external sources.

- Current Events

- RSS News Feeds

- Licensed Data like books and private data sets

- Your Private Data like your emails or sales information.

RAG helps you incorporate

`

    const cta = () => {
        return <Typography.Paragraph>Get it now. Show a named workflow where they can describe the data they want.</Typography.Paragraph>

    }


    useEffect(() => {document.title = 'PyroPrompts | Retrieval Augmented Generative AI'}, [])
    return <PageMarkdownContentWithSideCard
        title="Use External Data with RAG"
        markdown={markdown}
        sideCardTitle={undefined}
        sideCard={undefined}
        action={cta()}
        image='fire-robot-1-520x200.png'
    />
}

export function EnterpriseContent() {
    const markdown = `We help our clients adopt AI in their products and business operations.

We offer Enterprise packages to companies looking for full control of their own dedicated PyroPrompts instance.

This includes, but is not limited to:
- **Whitelabeled or Co-Branded Deployment of PyroPrompts**, so it has your look and feel, meaning your customers feel at-home.

- **Included Consulting Hours**, so you can get specific features implemented, meaning fewer integrations and less of your resources.

- **Dedicated Workflow Throughput and Volume**, so you can get the scale and speed you need.

- **Dedicated Data Set Storage**, enabling you to store and query massive data sets.

- **Security Protocols** to match your compliance needs.

- **Bulk Pricing**, so you can save money and automate more.

- **Uptime SLA** provides you a guarantee of availability.

- **Prioritized Customer Support and Feature Requests**, so beyond what you pay for in consulting hours, you still get the support you need.

- Much much more! Let us know your challenges.


If you are interested in exploring our Enterprise offering, please [contact us](/contact_us).
`
    const cta = () => {
        return <div>
            <Typography.Paragraph>Let's set up a call so we can explore how to solve your AI challenges.</Typography.Paragraph>
            <Link to="/contact_us">
                    <Button type='primary' size='large' style={{width: '100%', marginTop: '12px', height: '48px'}}>
                        Contact us
                    </Button>
                </Link>
            </div>
    }


    useEffect(() => {document.title = 'PyroPrompts | Enterprise Solutions'}, [])
    return <PageMarkdownContentWithSideCard
        title="Enterprise Solutions"
        markdown={markdown}
        sideCardTitle={undefined}
        sideCard={undefined}
        action={cta()}
        image='transpose-1-520x200.png'
    />
}


export const squeeze_pages = {
    workflow_scheduling: SqueezeWorkflowScheduling,
    workflow_event_handling: SqueezeWorkflowEventHandling,
    workflow_hosting: SqueezeWorkflowHosting,
    extension: SqueezeExtension,
    new_year_no_code_ai_marketing_masters: SqueezeNewYearNoCodeAiMarketingMasters,
    market_analysis_prompt_pack: SqueezeMarketAnalysisPromptPack,
    ai_for_digital_marketers: SqueezeForMarketers,
    ai_for_best_summer_ever: SqueezeBestSummerEver,
    enterprise: EnterpriseContent,
    black_friday_special: BlackFridayContent,
    cyber_monday_special: CyberMondayContent,
    // use_data_with_rag: RetrievalAugmentedGeneration,
    // ai_for_realtors
    // ai_for_health_coaches
    // ai_for_business_coaches
    // ai_for_recruiting
    // ai_for_job_searching
}
