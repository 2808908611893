import { ReactElement } from 'react';

import { AccountBillingMethod, SubscriptionRecurring, StoreItem, StoreCartItem, AiChatIntegration } from './types/types';
import { RootState } from './app/store';


export const COMMON_EMPTY_RESPONSE = {
    data: {
        count: 0,
        results: [],
    }
}


export const effectiveLifetimeUnitPrice = (storeItem: StoreItem, subscriptionTimeframe: SubscriptionRecurring) => {
    if (subscriptionTimeframe === 'lifetime') {
        return <>
            ${((storeItem.lifetime_price_cents || 0) / 100).toFixed(2)}
        </>
    }
    return <>
        -
    </>
}


export const effectiveMonthlyUnitPrice = (storeItem: StoreItem, subscriptionTimeframe: SubscriptionRecurring) => {
    if (subscriptionTimeframe === 'year') {
        return <>
            ${((storeItem.year_price_cents || 0) / (100 * 12)).toFixed(2)}
        </>
    }
    return <>
        ${((storeItem.month_price_cents || 0) / 100).toFixed(2)}
    </>
}

export const effectiveYearUnitPrice = (storeItem: StoreItem, subscriptionTimeframe: SubscriptionRecurring) => {
    if (subscriptionTimeframe === 'year') {
        return <>
            ${((storeItem.year_price_cents || 0) / 100).toFixed(2)}
        </>
    }
    return <>
        ${(((storeItem.month_price_cents || 0) / 100) * 12).toFixed(2)}
    </>
}


export const cartItemUnitPrice = (cartItem: StoreCartItem) => {
    if (cartItem.recurring === 'month') {
        return ((cartItem.item.month_price_cents || 0) / 100).toFixed(2)
    } else if (cartItem.recurring === 'year') {
        return ((cartItem.item.year_price_cents || 0) / 100).toFixed(2)
    } else if (cartItem.recurring === 'lifetime') {
        return ((cartItem.item.lifetime_price_cents || 0) / 100).toFixed(2)
    }
}

export const cartItemTotalPrice = (cartItem: StoreCartItem) => {
    if (cartItem.recurring === 'month') {
        return (cartItem.quantity * (cartItem.item.month_price_cents || 0) / 100).toFixed(2)
    } else if (cartItem.recurring === 'year') {
        return (cartItem.quantity * (cartItem.item.year_price_cents || 0) / 100).toFixed(2)
    } else if (cartItem.recurring === 'lifetime') {
        return (cartItem.quantity * (cartItem.item.lifetime_price_cents || 0) / 100).toFixed(2)
    }

}

export const cartTotalPrice = (cartItems: StoreCartItem[]) => {
    return (cartItems.reduce((agg, cartItem) => {
        if (cartItem.recurring === 'month') {
            return agg + (cartItem.quantity * (cartItem.item.month_price_cents || 0))
        } else if (cartItem.recurring === 'year') {
            return agg + (cartItem.quantity * (cartItem.item.year_price_cents || 0))
        } else if (cartItem.recurring === 'lifetime') {
            return agg + (cartItem.quantity * (cartItem.item.lifetime_price_cents || 0))
        }
        return agg
    }, 0) / 100).toFixed(2)
}


export const toTitleCase = (str: string) => {
    return str.toLowerCase().replace(/_/g, ' ').split(' ').map(function (word) {
        return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ');
}

export const objIsRootState = (maybeState: unknown): maybeState is RootState => {
    if (typeof maybeState === 'object' && maybeState !== null) {
        return 'cart' in maybeState
    }
    return false
} 

export const isNullish = (o: any): boolean => o === undefined || o === null


export function extractBracketContents(text: string): string[] {
    const regex = /\[([^\]]+)\]/g;
    let match: RegExpExecArray | null;
    const contents: string[] = [];
    const counts: { [key: string]: number } = {};

    while ((match = regex.exec(text)) !== null) {
        const content = match[1];
        if (counts[content] !== undefined) {
        // contents.push(content + counts[content]);
        counts[content]++;
        } else {
        contents.push(content);
        counts[content] = 1;
        }
    }

    return contents;
}
  
export function extractSingleLineBracketContents(text: string): string[] {
    const regex = /^\[([^\]]+)\]$/gm;
    let match: RegExpExecArray | null;
    const contents: string[] = [];
    const counts: { [key: string]: number } = {};

    while ((match = regex.exec(text)) !== null) {
        const content = match[1];
        if (counts[content] !== undefined) {
        // contents.push(content + counts[content]);
        counts[content]++;
        } else {
        contents.push(content);
        counts[content] = 1;
        }
    }

    return contents;
}
  
  
  
export function replaceBracketContents(text: string, bracketContentsMapping: { [key: string]: string }): string {
    let newText = text;

    for (const bracketContent in bracketContentsMapping) {
        const value = bracketContentsMapping[bracketContent];
        const regex = new RegExp(`\\[${bracketContent}\\]`, 'g');
        newText = newText.replace(regex, value);
    }

    return newText;
}


export function replaceBracketContentsWithNode(
  text: string,
  bracketContentsMapping: { [key: string]: string },
  style: React.CSSProperties
): ReactElement {
    let segments: ReactElement[] = [];
    let lastIndex = 0;

    // Find all bracketed contents in the text
    const allMatches: Array<{ index: number, bracketContent: string, fullMatch: string }> = [];
    const regex = /\[([^\]]+)\]/g;
    let match;
    while ((match = regex.exec(text)) !== null) {
        if (bracketContentsMapping[match[1]]) {
            allMatches.push({
                index: match.index,
                bracketContent: match[1],
                fullMatch: match[0]
            });
        }
    }

    // Sort matches based on their index in the text
    allMatches.sort((a, b) => a.index - b.index);

    // Iterate through sorted matches to generate segments
    for (const matchInfo of allMatches) {
        const value = bracketContentsMapping[matchInfo.bracketContent];

        // Add unchanged text segment
        if (matchInfo.index > lastIndex) {
            segments.push(<span key={lastIndex}>{text.substring(lastIndex, matchInfo.index)}</span>);
        }

        // Add changed text segment with passed style
        segments.push(<span key={matchInfo.index} className='prompt-template-replaced' style={style}>{value}</span>);

        lastIndex = matchInfo.index + matchInfo.fullMatch.length;
    }

    // Add any remaining unchanged text
    if (lastIndex < text.length) {
        segments.push(<span key={lastIndex}>{text.substring(lastIndex)}</span>);
    }

    return <>{segments}</>;
}

const tagColorMap: {[key: string]: string} = {
    'Free': '#8bbb11',
    'Monetize': '#e8b339',
    'Commercial': '#d32029',
    'Writing': '#3c9ae8',
    'News': 'blue',
    'Marketing': 'purple',
    'Business': 'green',
    'Learning': 'orange',
    'Fun': 'pink',
    'Education': 'gold',
    'Tools': 'cyan',
    'Design': 'geekblue',
    'Personal': 'magenta',
    'Entertainment': 'volcano',
    'Health': 'lime',
    'Science': 'olive',
    'Tech': 'gold',
    'Sports': 'blue',
    'Travel': 'orange',
    'Food': 'lime',
    'Family': 'green',
    'Relationships': 'cyan',
}
export function getTagColor(tag: string): string | undefined {
    // some supported colors: magenta red volcano orange gold lime green cyan blue geekblue purple
    return tagColorMap[tag]
}

export function isBetaUser(account_name: string | null | undefined) {
    return account_name === "ferrants"
}

export function custom_models_from_ai_chat_integrations(ai_chat_integrations: AiChatIntegration[]){
    const custom_models = (ai_chat_integrations || []).reduce((agg, ai_chat_integration) => { 
        return [...agg, ...(ai_chat_integration.usable_models || '').split(',').map((model) => {
            // remove surrounding whitespace from model
            const clean_model = model.trim()
            return {id: `integrated_model.${ai_chat_integration.id}.${clean_model}`, name: `Custom Model via ${ai_chat_integration.name}: ${clean_model}`}
        })]
    }, [] as {id: string, name: string}[])
    return custom_models
}
