import { CheckCircleOutlined, RightCircleOutlined, IssuesCloseOutlined, PlayCircleOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Tooltip, Typography, Alert } from 'antd';
import { useEffect } from "react";

import { ApiIntegrationsList } from '../../features/apiIntegrationsList/ApiIntegrationsList';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { selectAccount } from '../../features/login/loginSlice'
import { Link, useParams, useSearchParams } from 'react-router-dom';

export function Integrations() {
    useEffect(() => {document.title = 'PyroPrompts | Integrations'}, [])

    const [searchParams, setSearchParams] = useSearchParams();

    const account = useAppSelector(selectAccount);

    const successFlag = searchParams.get("success")
    console.log("account", {account})


    return (
        <div className="App">
            <div className="App-content">
                <Typography.Title level={1}>Integrations</Typography.Title>
                <div className="App-page-content">
                    <Typography.Paragraph>Manage Integrations and API Tokens. Connect to integrate other services with PyroPrompts. <Link to={`/documentation`}>Learn more.</Link></Typography.Paragraph>
                    {!account && (
                        <>
                            <Typography.Paragraph>Log In or Sign Up to manage integrations. Integrations allow you to pull signals programatically or send data directly to your platform of choice.</Typography.Paragraph>
                        </>
                    )}
                    {successFlag === "1" && (
                        <Alert
                          message={"Integration Success"}
                          description={"Your integration was successfully added! You may begin using it."}
                          type="success"
                            style={{marginBottom: '12px'}}
                        />
                    )}
                    {successFlag === "0" && (
                        <Alert
                          message={"Integration Failure"}
                          description={"We failed to add your integration. You may try again or contact support."}
                          type="error"
                            style={{marginBottom: '20px'}}
                        />
                    )}
                    {account && (
                        <ApiIntegrationsList />
                    )}
                </div>
            </div>
        </div>
    );
}
