export interface Account {
    id: string
    name: string
    share_url?: string
    profile_pic_path?: string
}

export interface MyAccount extends Account {
    prompt_length_cap?: number
    data_provider_cap?: number
    data_set_cap?: number
    dashboard_cap?: number
    workflow_cap?: number
    daily_ai_chat_cap?: number
    current_credits?: number
}


export interface AccountBio {
    account: Account
    account_id: string
    bio: string
    is_searchable: number
}

export interface DataProvider {
    id: string
    name: string
    account: Account
    account_id: string
    data_set_cap?: number
    data_set_count?: number
}

export interface DataSet {
    id: string
    name: string
    data_provider_id: string
    data_provider: DataProvider
    account_id: string
    account: Account
    subscribed: boolean
    description?: string
    is_published: number
    is_public: number
    created_date: string
    s3_source_url_updated_date: string
    detail?: string
    tags?: string | null
    is_populated: boolean
    img_path?: string | null
}

export interface Tag {
    id: string
    name: string
    color: string
    description?: string
    category?: string
    share_url?: string
}

export interface Prompt {
    id: string
    name: string
    account_id: string
    account: Account
    description?: string
    content?: string
    is_published: number
    is_public: number
    created_date: string
    save_count?: number
    saved?: boolean
    star_count?: number
    starred?: boolean
    tags?: Tag[]
    share_url: string
}

export interface Project {
    id: string
    name: string
    description?: string
    created_date: string
}

export interface ProjectSnippet {
    id: string
    name: string
    description?: string
    content?: string
    project_id: string
    created_date: string
}

export interface PromptPack {
    id: string
    name: string
    description?: string
    prompts: Prompt[]
    projects: Project[]
}

export interface AiChat {
    id: string
    account_id: string
    account: Account
    prompt?: string
    response?: string
    platform?: string
    model?: string
    created_date: string
    completion_tokens_consumed?: number
    prompt_tokens_consumed?: number
    total_tokens_consumed?: number
}

export interface DataSetSubscptionMethod {
    data_set: DataSet
    data_set_id: string
    id: string
    enabled: number
    expired: number
    use_cap?: number
    expiration_date?: string
    subscription_expiration_days?: number
    subscription_expiration_date?: string
}

export interface ForecastContext {
    [key: string]: string | number | undefined
}


export interface Forecast {
    id: string
    symbol: string
    move: number
    minutes: number
    created_date: string
    updated_date: string
    data_set_id: string
    data_set: DataSet
    context: ForecastContext
}


export interface SignalContext {
    [key: string]: string | number | undefined
}


export interface Signal {
    id: string
    symbol: string
    value: number
    ttl_minutes: number
    created_date: string
    updated_date: string
    data_set_id: string
    data_set: DataSet
    context: SignalContext
}

export interface SignalHistory {
    v: number
    t: string
    c: SignalContext
}

export interface DataSetRecordContext {
    [key: string]: string | number | undefined
}

export interface DataSetRecord {
    id: string
    key: string
    value: number | string
    ttl_minutes: number
    created_date: string
    updated_date: string
    data_set_id: string
    data_set: DataSet
    context: DataSetRecordContext
}

export interface DataSetRecord {
    v: number
    t: string
    c: DataSetRecordContext
}



export type IntegrationType = 'oauth2_api_token' | 'simple_api_token' | 'trading_view_webhook' | 'discord_webhook' | 'telegram_token' | 'x_authentication' | 'ai_chat_integration'

export interface Integration {
    id: string
    name: string
    account_id: string
    created_date: string
    type: IntegrationType

}

export interface AiChatIntegration {
    id: string
    account_id: string
    name: string
    provider: string
    available_models: string
    usable_models: string
}

// Workflow Types
export type WorkflowConfigDataSetSymbolConditionType = 'data_set_symbol'
export type WorkflowConfigDataSetSymbolArgsThresholdOperator = "eq" | "lt" | "lte" | "gt" | "gte"

export interface WorkflowConfigDataSetSymbolArgsThreshold {
    operator: WorkflowConfigDataSetSymbolArgsThresholdOperator
    value: number
}

export interface WorkflowConfigDataSetSymbolArgs {
    symbol?: string
    data_set_id?: string
    thresholds: WorkflowConfigDataSetSymbolArgsThreshold[]
}

export interface WorkflowConfigCondition {
    condition_type: WorkflowConfigDataSetSymbolConditionType
    args: WorkflowConfigDataSetSymbolArgs
}

export type WorkflowConfigDiscordActionType = 'discord_webhook_send_message'
export type WorkflowConfigTelegramActionType = 'telegram_send_message'
export type WorkflowTriggerTimedTriggerType = 'timed'
export type WorkflowTriggerFormSubmitTriggerType = 'form_submit'

export type WorkflowStepExecutePromptType = 'execute_prompt'
export type WorkflowStepSendEmailType = 'send_email'
export type WorkflowStepSetProjectSnippetType = 'set_project_snippet'
export type WorkflowStepSetResultType = 'set_result'
export type WorkflowStepLookupInfoSetType = 'lookup_info_set'
export type WorkflowStepRunWorkflowsType = 'run_workflows'
export type WorkflowStepPostToXType = 'post_to_x'

export type WorkflowConfigActionTypes = WorkflowConfigDiscordActionType | WorkflowConfigTelegramActionType
export type WorkflowConfigTriggerTypes = WorkflowTriggerTimedTriggerType | WorkflowTriggerFormSubmitTriggerType
export type WorkflowConfigStepTypes = WorkflowStepExecutePromptType | WorkflowStepSendEmailType | WorkflowStepSetProjectSnippetType | WorkflowStepSetResultType | WorkflowStepLookupInfoSetType | WorkflowStepRunWorkflowsType | WorkflowStepPostToXType


// export interface WorkflowConfigDiscordActionArgs {
//     integration_id?: string
//     message: string
//     username?: string
// }

// export interface WorkflowConfigTelegramActionArgs {
//     integration_id: string
//     message: string
//     chat_id: string
// }

export interface FieldValue {
    field: string
    value: string
    upstream_ref?: string

}

export interface FieldUpstreamRef {
    field: string
    upstream_ref: string
    value?: string
}

export interface FormSubmittionFieldOptions {
    label: string
    value: string
}

export interface FormSubmissionField {
    field: string
    label?: string
    value?: string
    default_value?: string
    placeholder?: string
    field_type: string
    options?: FormSubmittionFieldOptions[]
    description?: string
    required?: boolean
}

export type WorkflowTriggerTimedDailyScheduleType = 'daily'
export type WorkflowTriggerTimedWeeklyScheduleType = 'weekly'
export type WorkflowTriggerTimedMonthlyScheduleType = 'monthly'
export type WorkflowTriggerTimedScheduleType = WorkflowTriggerTimedDailyScheduleType | WorkflowTriggerTimedWeeklyScheduleType | WorkflowTriggerTimedMonthlyScheduleType

export interface WorkflowTriggerTimedDailySchedule {
    type: WorkflowTriggerTimedDailyScheduleType
}

export type WorkflowTriggerTimedWeeklyDays = "Mo" | "Tu" | "We" | "Th" | "Fr" | "Sa" | "Su"
export interface WorkflowTriggerTimedWeeklySchedule {
    type: WorkflowTriggerTimedWeeklyScheduleType
    week_days: WorkflowTriggerTimedWeeklyDays[]
}

export interface WorkflowTriggerTimedMonthlySchedule {
    type: WorkflowTriggerTimedMonthlyScheduleType
    month_days: number[]
}

export interface WorkflowTriggerTimedTriggerArgs {
    schedule: WorkflowTriggerTimedDailySchedule | WorkflowTriggerTimedWeeklySchedule | WorkflowTriggerTimedMonthlySchedule
    schedule_hour: number
    // submission_params: FieldValue[]
    submission_params: FieldValue[]
}

export interface WorkflowTriggerFormSubmitTriggerArgs {
    form_title?: string
    form_button_text?: string
    form_description?: string
    form_submission_daily_cap?: number
    submission_params: FormSubmissionField[]
}



// export interface WorkflowConfigDiscordAction {
//     action_type: WorkflowConfigDiscordActionType
//     args: WorkflowConfigDiscordActionArgs
// }

// export interface WorkflowConfigTelegramAction {
//     action_type: WorkflowConfigTelegramActionType
//     args: WorkflowConfigTelegramActionArgs
// }

export interface WorkflowTriggerTimed {
    trigger_type: WorkflowTriggerTimedTriggerType
    args: WorkflowTriggerTimedTriggerArgs
}

export interface WorkflowTriggerFormSubmit {
    trigger_type: WorkflowTriggerFormSubmitTriggerType
    args: WorkflowTriggerFormSubmitTriggerArgs
}

export type WorkflowBlockOutputMethodWholeResponse = 'whole_prompt_response'
export type WorkflowBlockOutputMethodParsedSingle = 'parsed_single'
export type WorkflowBlockOutputMethodParsedList = 'parsed_list'
export type WorkflowBlockOutputMethods = WorkflowBlockOutputMethodWholeResponse | WorkflowBlockOutputMethodParsedSingle | WorkflowBlockOutputMethodParsedList

export interface WorkflowPromptBlockOutputWholePrompt {
    output_method_type: WorkflowBlockOutputMethodWholeResponse
    args: {
        set_ref?: string
    }
}

export interface WorkflowPromptBlockOutputParsedSingle {
    output_method_type: WorkflowBlockOutputMethodParsedSingle
    args: {
        set_ref?: string
    }
}

export interface WorkflowPromptBlockOutputParsedList {
    output_method_type: WorkflowBlockOutputMethodParsedList
    args: {
        set_ref?: string
    }
}

export type WorkflowBlockStepTemplateVar = FieldValue | FieldUpstreamRef

export interface WorkflowPromptBlockStep {
    block_step_type: WorkflowStepExecutePromptType
    args: {
        prompt_id: string
        model: string
        output_method: WorkflowPromptBlockOutputWholePrompt | WorkflowPromptBlockOutputParsedSingle | WorkflowPromptBlockOutputParsedList
        set_template_vars: WorkflowBlockStepTemplateVar[]
    }

}

export interface WorkflowEmailBlockStep {
    block_step_type: WorkflowStepSendEmailType
    args: {
        subject_template: string
        content_markdown_template: string
        set_template_vars: WorkflowBlockStepTemplateVar[]
    }
}

export interface WorkflowSetProjectSnippetBlockStep {
    block_step_type: WorkflowStepSetProjectSnippetType
    args: {
        snippet_name: string
        snippet_content_template: string
        set_template_vars: WorkflowBlockStepTemplateVar[]
    }
}

export interface WorkflowSetResultBlockStep {
    block_step_type: WorkflowStepSetResultType
    args: {
        result_template: string
        set_template_vars: WorkflowBlockStepTemplateVar[]
    }
}

export interface WorkflowLookupInfoSetBlockStep {
    block_step_type: WorkflowStepLookupInfoSetType
    args: {
        info_set_id: string
        source_lookup_params: {[key: string]: string},
        output_method: WorkflowPromptBlockOutputWholePrompt | WorkflowPromptBlockOutputParsedSingle | WorkflowPromptBlockOutputParsedList
        set_template_vars: WorkflowBlockStepTemplateVar[]
    }
}


export interface WorkflowRunWorkflowsBlockStep {
    block_step_type: WorkflowStepRunWorkflowsType
    args: {
        workflow_name_whitelist: string
        workflow_instruction: string
        output_method: WorkflowPromptBlockOutputWholePrompt | WorkflowPromptBlockOutputParsedSingle | WorkflowPromptBlockOutputParsedList
        set_template_vars: WorkflowBlockStepTemplateVar[]
        // info_set_id: string
        // source_lookup_params: {[key: string]: string},
        // output_method: WorkflowPromptBlockOutputWholePrompt | WorkflowPromptBlockOutputParsedSingle | WorkflowPromptBlockOutputParsedList
        // set_template_vars: WorkflowBlockStepTemplateVar[]
    }
}

export interface WorkflowPostToXBlockStep {
    block_step_type: WorkflowStepPostToXType
    args: {
        content: string
        integration_id: string
        output_method: WorkflowPromptBlockOutputWholePrompt | WorkflowPromptBlockOutputParsedSingle | WorkflowPromptBlockOutputParsedList
        set_template_vars: WorkflowBlockStepTemplateVar[]
    }
}

export type WorkflowBlockStep = WorkflowPromptBlockStep | WorkflowEmailBlockStep | WorkflowSetProjectSnippetBlockStep | WorkflowSetResultBlockStep | WorkflowLookupInfoSetBlockStep | WorkflowRunWorkflowsBlockStep | WorkflowPostToXBlockStep
export interface WorkflowStepDefinition {
    schema_version: '1'
    args: {
        block_name?: string
        block_description?: string
        block_inputs?: string[]
        block_outputs?: string[]
        block_steps?: WorkflowBlockStep[]
    }

}
// discord_webhook_send_message

// export type WorkflowConfigAction  = WorkflowConfigDiscordAction | WorkflowConfigTelegramAction
export type WorkflowTrigger  = WorkflowTriggerTimed | WorkflowTriggerFormSubmit

export interface WorkflowConfig {
    triggers: WorkflowTrigger[]
    step_definition?: WorkflowStepDefinition

    // triggers: WorkflowConfigCondition[]
    // actions: WorkflowConfigAction[]
}

export interface Workflow {
    id: string
    account_id: string
    name: string
    created_date: string
    project_id?: string
    project?: Project
    config: WorkflowConfig
    enabled: number
    credit_price: number | null
}

export interface WorkflowExecution {
    id: string
    workflow_id: string
    status_message?: string
    status?: string
    result?: string
    created_date: string
    form_params?: {[key: string]: string}
}

export interface NamedWorkflow {
    name: string
    workflow_id: string
    workflow_form: WorkflowTriggerFormSubmitTriggerArgs
    is_mine?: boolean
    marketing_markdown?: string
    img_path?: string
    credit_price: number | null
}


export interface WidgetLayout {
    // width?: string
    x: number
    y: number
    w: number
    h: number
}

// export type WidgetType = "signal_stream"

export interface SignalQueryParams {
    context_filter?: string
}
export type DateFilter = '30_days' | '7_days' | 'today'




export interface BlankWidgetArgs {
    data_set_id?: string
    show_field_as_value?: string
    content?: string
    default_date_filter?: DateFilter
    query_params?: SignalQueryParams
    ordering?: string
    add_buttons?: boolean
    add_context_columns?: boolean
    inverse_bull_bear?: boolean
    limit?: number
}


export interface BlankWidgetConfig {
    widget_type: string
    widget_args: BlankWidgetArgs
}

export interface SignalStreamWidgetArgs {
    data_set_id?: string
    ordering?: string
    show_field_as_value?: string
    query_params?: SignalQueryParams
    default_date_filter?: DateFilter
    add_buttons?: boolean
    inverse_bull_bear?: boolean
    limit?: number
}

export interface SignalStreamWidgetConfig {
    widget_type: "signal_stream"
    widget_args: SignalStreamWidgetArgs
}

export interface SignalTableWidgetArgs {
    data_set_id?: string
    ordering?: string
    query_params?: SignalQueryParams
    default_date_filter?: DateFilter
    add_buttons?: boolean
    add_context_columns?: boolean
    limit?: number
}

export interface SignalTableWidgetConfig {
    widget_type: "signal_table"
    widget_args: SignalTableWidgetArgs
}

export interface TextWidgetArgs {
    content?: string
}

export interface TextWidgetConfig {
    widget_type: "text"
    widget_args: TextWidgetArgs
}

export interface DynamicTextWidgetArgs {
    data_set_id?: string
    ordering?: string
    query_params?: SignalQueryParams
    default_date_filter?: DateFilter
    content?: string
    limit?: number
}

export interface DynamicTextWidgetConfig {
    widget_type: "dynamic_text"
    widget_args: DynamicTextWidgetArgs
}


export interface Widget {
    id: string
    name: string
    layout: WidgetLayout
    widget_config: SignalStreamWidgetConfig | SignalTableWidgetConfig | TextWidgetConfig | DynamicTextWidgetConfig | BlankWidgetConfig
    // TweetWidget SocialMediaPost? AI to generate content? Premium for that
}


export interface DashboardConfig {
    widgets: Widget[]
}


export interface Dashboard {
    id: string
    name: string
    config: DashboardConfig
}

// export type SubscriptionPlan = 'basic' | 'pro' | 'elite'
export type SubscriptionRecurring = 'month' | 'year' | 'lifetime'
// export type SubscriptionId = 'basic' | 'pro_month' | 'pro_year' | 'elite_month' | 'elite_year' | 'pro_one_time' | 'elite_one_time'

export interface AccountBillingMethod {
    account_id: string
    billing_method: string
    // subscription_id: SubscriptionId
    status: string
    // expiration_date: string
}


export interface StoreItem {
    id: string
    name: string
    description: string
    lifetime_price_cents: number | null
    month_price_cents: number | null
    year_price_cents: number | null
    multiple_disallowed: number
    available: number
    sku?: string
    category?: string
    featuredTag: string
}

export interface StoreCart {
    id: string
    status: string
    created_date: string
}

export interface StoreCartItem {
    id: string
    item_id: string
    item: StoreItem
    cart_id: string
    quantity: number
    recurring: SubscriptionRecurring
    created_date: string
}

export interface AccountItemPurchase {
    id: string
    item_id: string
    item: StoreItem
    status: string
    quantity: number
    expiration_date: string | null
    created_date: string
}

export interface FeatureFlag {
    id: string
    code_name: string
    description: string
}


export interface CreditTransaction {
    id: string
    credits: number
    transaction_type: string
    description?: string
    transaction_date: string
    workflow_execution_id?: string
    account_grant_id?: string
    account_item_purchase_id?: string
}


export interface InfoSetSourceLookupParams {
    fields: FormSubmissionField[]
}


export interface InfoSet {
    id: string
    name: string
    description: string
    is_public: number
    source_type: string
    source_lookup_params: InfoSetSourceLookupParams
    enabled: number
}

export const themesArr = ['light', 'pyro-light', 'pyro-dark', 'dark', 'pro', 'purple' , 'green', 'catppuccin-mocha', 'catppuccin-latte', 'alt-light', 'alt-dark', 'dracula', 'nord-light', 'nord-dark'] as const;
export type Theme = typeof themesArr[number];

export interface RandomDict {
    [key: string]: string | number | undefined
}

export interface CheckNewsletterResponseType {
    email: string | null
    submitted: boolean
    verified: boolean
    unsubscribed: boolean
    email_problem: boolean
}
